import React, { useState } from 'react';
import { CouponFailed, CouponSuccess } from '@assets';
import {
  Drawer,
  useMediaQuery,
  useTheme,
  Dialog,
  Typography,
  Button,
} from '@mui/material';
import { CouponSuccessLottie } from '@assets';
import LottieComponent from '@components/Common/Lottie';
import Svg from '@components/Common/Svg';
import './couponStatusDialogue.scss';
import { getCouponType } from '@utils/common';
export default function CouponStatusDialogue(props) {
  const {
    openStatusModal,
    setOpenStatusModal,
    status,
    setCouponStatus,
    isCouponDiscount,
    isCouponCode,
    isHollowOffer,
    isDoubleDip,
    isCustomCoupon,
    appliedCouponCode = null,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const renderCouponType = getCouponType(isCustomCoupon, isDoubleDip);
  const handleDrawerClose = () => {
    setCouponStatus('');
    setOpenStatusModal(false);
  };

  const CustomDialogContent = (onClose, status, isHollowOffer) => {
    return (
      <div className='El-customDialogContent'>
        <Svg
          className='EL-footerLogowhite'
          src={status == 'success' ? CouponSuccess : CouponFailed}
          alt='logo footer'
        />

        {status === 'success' && (
          <div className='El-couponSuccess'>
            <LottieComponent
              animationData={CouponSuccessLottie}
              loop={true}
              play={true}
              speed={1}
              height={369}
              width={'100%'}
              className='El-couponSuccessLottie'
            />
            <Typography variant='body2' component='p' color='text.secondary'>
              {!isHollowOffer
                ? `'${isCouponCode}' applied`
                : 'VISA offer applied'}
            </Typography>
            <Typography gutterBottom variant='h5' component='h5'>
              {!isHollowOffer ? (
                <>
                  Yay, you saved{}
                  <span> {`₹${parseFloat(isCouponDiscount).toFixed(1)}`}</span>
                  &nbsp;{isDoubleDip && `additional discount`}&nbsp;on your
                  stay.
                  {renderCouponType !== 'CustomCoupon' &&
                    !(
                      appliedCouponCode?.doubleDipCoupon &&
                      appliedCouponCode?.sitewideCoupon
                    ) && (
                      <Typography
                        variant='body1'
                        component='p'
                        color='text.secondary'
                      >
                        Apply more coupon to get more discount
                      </Typography>
                    )}
                </>
              ) : (
                <span>Discount will be auto applied on the payment page</span>
              )}
            </Typography>
          </div>
        )}

        {status === 'NotApplicable' && (
          <div className='El-couponFailed'>
            <Typography gutterBottom variant='h5' component='h5'>
              Sorry, this coupon is not applicable.
            </Typography>
            <Typography variant='body2' component='p' color='text.secondary'>
              {isCouponDiscount}
            </Typography>
          </div>
        )}

        {status == 'Invalid' && (
          <div className='El-couponFailed'>
            <Typography gutterBottom variant='h5' component='h5'>
              Sorry, invalid coupon code.
            </Typography>
            <Typography variant='body2' component='p' color='text.secondary'>
              {isCouponDiscount}
            </Typography>
          </div>
        )}

        <Button
          variant='contained'
          disableElevation
          size='large'
          color='secondary'
          onClick={handleDrawerClose}
          fullWidth
        >
          Okay
        </Button>
      </div>
    );
  };

  return (
    <>
      {isMobile ? (
        // Render drawer for mobile
        <Drawer
          anchor='bottom'
          size='lg'
          open={openStatusModal}
          onClose={handleDrawerClose}
          className='EL-CouponStatusDrawer'
        >
          {CustomDialogContent(handleDrawerClose, status, isHollowOffer)}
        </Drawer>
      ) : (
        // Render modal for web
        <Dialog
          className='EL-CouponStatusDialog'
          open={openStatusModal}
          onClose={handleDrawerClose}
          aria-describedby='alert-dialog-slide-description'
        >
          {CustomDialogContent(handleDrawerClose, status, isHollowOffer)}
        </Dialog>
      )}
    </>
  );
}
