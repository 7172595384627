import GuestOpen from '@components/CheckAvailability/AddGuestList';
import DatePickerDrawer from '@components/CheckAvailability/DatepickerOption/DatePickerDrawer';
import DatePickerModal from '@components/CheckAvailability/DatepickerOption/DatePickerModal';

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Button, TextField, useMediaQuery, useTheme } from '@mui/material';
import { updateReviewBookingDetails } from '@reducers/bookingReducer';
import {
  booking,
  updateTheCalender,
  updatingPricing,
} from '@reducers/propertyReducer';
import { pricingRefactored, userDetails } from '@utils/common';
import { dateFormat } from '@utils/customText';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchingUpdatedPricing,
  gettingBookingRes,
} from '../../../actions/propertiesAction';
import BookNow from '../BookNow';
import './chekinCheckout.scss';

// Import custom hooks
import useBookingDetails from '@customhooks/useBookingDetails';
import useCoupon from '@customhooks/useCoupon';
import { saveFilterValues } from '@utils/common';

const CheckinCheckoutDetail = (props) => {
  const {
    numberOfGuests,
    setNumberOfGuests,
    numberOfChildren,
    setNumberOfChildren,
    checkInDate,
    setCheckInDate,
    checkOutDate,
    setCheckOutDate,
    isCheckInDatePickerOpen,
    setIsCheckInDatePickerOpen,
    checkInQueryParam,
    checkOutQueryParam,
    numberOfGuestsQueryParam,
    numberOfChildrenQueryParam,
    handleCheckInDateClick,
    firstDate,
    availabilityCalenderList,
    setCalenderModalError,
    calenderModalError,
    bookingId,
    isBookingSuccessStatus,
    pathname,
    fetchingBookingDetailsStatus,
    fetchingBookingDetailsRes,
    isPartialPayment,
  } = useBookingDetails();
  const { elicashCount, elicashApplied } = useSelector((state) => state?.glob);
  const {
    couponCode,
    couponId,
    isCouponRemoved,
    isHollowCouponRemoved,
    isCustomCouponRemoved,
    isCustomDoubleDipCouponRemoved,
    isCouponApplied,
    isCustomCouponApplied,
    isCustomDoubleDipCouponApplied,
  } = useCoupon();

  const copyAvailabilityCalenderList = JSON.parse(
    sessionStorage.getItem('copyData')
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();

  const {
    id,
    max_occupancy,
    standard_guests,
    isbookNow,
    selectedCheckInDate,
    selectedcheckOutDate,
    isModifyClicked,
    setIsModifyClicked,
    totalPayableAmount,
    max_children,
    max_adults,
    gettingUpdatedPricing,
    applyButtonClicked,
    staahMapping,
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [clearDatesModal, setIsClearDatesModal] = useState(false);
  const [isGuestDrawer, setGuestDrawer] = useState(false);
  const [isOuterCliked, setIsOuterClicked] = useState(false);
  const [selectDate, setSelectDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const { fetchingUpdatingPricing } = useSelector((state) => state.property);
  const filterValues = localStorage.getItem('filterValues');

  const { loader, showAvailabilty } = useSelector((state) => state.glob);

  const urlParts = location.pathname.split('/');
  const slug = urlParts[urlParts.length - 1];
  const searchParams = new URLSearchParams(location?.search);
  const isDetailPage =
    (location.pathname.includes('villas-in') && urlParts.length == 3) ||
    (searchParams.has('propertyId') && location.pathname.includes('villas-in'));

  const { gettingProfileDetails } = useSelector((state) => state.profile);

  const handleGuestClose = () => {
    setIsMenuOpen(false);
  };

  const handleMenuOpen = (event) => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleModalClose = () => {
    setIsCheckInDatePickerOpen(false);
    setIsModifyClicked(false);
  };
  // useEffect(() => {
  //   if (bookingId && location.pathname.startsWith('/villas')) {
  //     setCheckInDate(
  //       dayjs(fetchingBookingDetailsRes?.checkin_on).format('DD MMM, YYYY')
  //     );
  //     setCheckOutDate(
  //       dayjs(fetchingBookingDetailsRes?.checkout_on).format('DD MMM, YYYY')
  //     );
  //     setNumberOfGuests(fetchingBookingDetailsRes?.adults);
  //     setNumberOfChildren(fetchingBookingDetailsRes?.children);
  //     setIsMenuOpen(false);
  //   }
  // }, [showAvailabilty]);

  useEffect(() => {
    const bookingParams = searchParams.get('bookingId')
      ? searchParams.get('bookingId')
      : '';

    if (
      bookingId &&
      (location.pathname.startsWith('/booking') || isDetailPage) &&
      bookingParams
    ) {
      setCheckInDate(
        dayjs(fetchingBookingDetailsRes?.checkin_on).format(dateFormat.ddmmyy)
      );
      setCheckOutDate(
        dayjs(fetchingBookingDetailsRes?.checkout_on).format(dateFormat.ddmmyy)
      );
      setNumberOfGuests(fetchingBookingDetailsRes?.adults);
      setNumberOfChildren(fetchingBookingDetailsRes?.children);
      setIsMenuOpen(false);
    } else {
      dispatch(gettingBookingRes(''));
      setSelectDate([
        {
          startDate: new Date(),
          endDate: new Date(), // Adding 1 day (24 hours) to firstDate
          key: 'selection',
        },
      ]);
      setCheckOutDate('');
      setCheckInDate('');
      dispatch(fetchingUpdatedPricing(false));
    }
  }, [showAvailabilty]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    const bookingParams = searchParams.has('bookingId');
    if (clearDatesModal) {
      if (
        (location.pathname.startsWith('/booking') ||
          (isDetailPage && bookingParams)) &&
        !isCheckInDatePickerOpen
      ) {
        setIsCheckInDatePickerOpen(false);
        setIsMenuOpen(false);
      } else {
        setIsMenuOpen(false);
        setIsCheckInDatePickerOpen(true);
      }
    } else if (
      checkInDate &&
      checkOutDate &&
      dayjs(checkOutDate).isAfter(dayjs(checkInDate).add(1, 'day')) &&
      pathname !== '/booking'
    ) {
      setCalenderModalError(false);
    } else if (dayjs(checkOutDate).isAfter(dayjs(checkInDate).add(2, 'day'))) {
      setIsMenuOpen(false);
      setCalenderModalError(false);
    }
  }, [checkInDate, checkOutDate, clearDatesModal]);

  const handleUpdatingPricing = async (e) => {
    e.preventDefault();
    const formattedCheckInDate = dayjs(checkInDate, 'DD MMM,YYYY').format(
      'YYYY-MM-DD'
    );
    const formattedCheckOutDate = dayjs(checkOutDate, 'DD MMM,YYYY').format(
      'YYYY-MM-DD'
    );
    let data = {
      property_id: id,
      checkin_on: formattedCheckInDate,
      checkout_on: formattedCheckOutDate,
      adults: numberOfGuests,
      children: numberOfChildren,
      cashback_amount: elicashCount,
      ...(couponCode &&
        couponCode &&
        !isCustomCouponApplied &&
        !isCustomDoubleDipCouponApplied && {
          coupon_code: couponCode,

          //|| fetchingBookingDetailsRes?.Order?.Coupons[0]?.Offer?.id,
        }),

      ...(couponId &&
        !isCouponApplied &&
        !isCustomCouponApplied && {
          coupon_code: undefined,
          coupon_id: couponId,
          is_additional_discount: undefined,

          // ||fetchingBookingDetailsRes?.Order?.Coupons[0]?.Offer?.id,
        }),

      ...(isPartialPayment && {
        property_payment_term_id: pricingRefactored(
          gettingUpdatedPricing?.PaymentTerms ||
            gettingUpdatedPricing?.Property?.PaymentTerms
        )?.PropertyPaymentTerm?.id,
      }),
    };
    if (location.pathname !== '/booking') {
      await dispatch(updatingPricing(data));
      await setIsMenuOpen(false);
    } else {
      const searchParams = new URLSearchParams(location?.search);
      const bookingId = searchParams.get('id');
      const id = searchParams.get('propertyId');
      const newBookingData = {
        bookingId: bookingId,
        property_id: parseInt(id),
      };
      data = Object.assign(data, newBookingData);
      dispatch(updateReviewBookingDetails(data));
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (
      isDetailPage &&
      !location.pathname.startsWith('/booking') &&
      ((new URLSearchParams(location.search).has('propertyId') &&
        id === fetchingBookingDetailsRes?.Property?.id) ||
        (slug && slug === fetchingBookingDetailsRes?.Property?.slug))
    ) {
      if (checkInDate && checkOutDate && !isMenuOpen) {
        const formattedCheckInDate = dayjs(checkInDate, 'DD MMM,YYYY').format(
          'YYYY-MM-DD'
        );
        const formattedCheckOutDate = dayjs(checkOutDate, 'DD MMM,YYYY').format(
          'YYYY-MM-DD'
        );
        const data = {
          bookingId,
          property_id: parseInt(id),
          checkin_on: formattedCheckInDate,
          checkout_on: formattedCheckOutDate,
          adults: numberOfGuests,
          children: numberOfChildren,
          cashback_amount: elicashCount,
          ...(couponCode &&
            couponCode &&
            !isCustomCouponApplied &&
            !isCustomDoubleDipCouponApplied && {
              coupon_code: couponCode,

              //|| fetchingBookingDetailsRes?.Order?.Coupons[0]?.Offer?.id,
            }),
          ...(couponCode &&
            isCouponApplied && {
              is_additional_discount: true,
            }),
          ...(couponId &&
            !isCouponApplied &&
            !isCustomCouponApplied && {
              coupon_code: undefined,
              coupon_id: couponId,
              is_additional_discount: undefined,

              // ||fetchingBookingDetailsRes?.Order?.Coupons[0]?.Offer?.id,
            }),
          ...(isPartialPayment && {
            property_payment_term_id: pricingRefactored(
              gettingUpdatedPricing?.PaymentTerms ||
                gettingUpdatedPricing?.Property?.PaymentTerms
            )?.PropertyPaymentTerm?.id,
          }),
        };
        dispatch(updateReviewBookingDetails(data));
        setIsMenuOpen(false);
      }
    } else if (bookingId !== '') {
      if (
        id !== fetchingBookingDetailsRes?.property_id ||
        slug !== fetchingBookingDetailsRes?.Property?.slug
      ) {
        setNumberOfGuests(1);
        setNumberOfChildren(0);
        setCheckInDate('');
        setCheckOutDate('');
      }
    } else if (
      (new URLSearchParams(location.search).has('propertyId') || slug) &&
      filterValues &&
      !location.pathname.startsWith('/booking') &&
      !isCheckInDatePickerOpen &&
      !isMenuOpen
    ) {
      const parsedFilterValues = JSON.parse(filterValues);
      setCheckInDate(parsedFilterValues.checkInDate);
      setCheckOutDate(parsedFilterValues.checkOutDate);
      setNumberOfGuests(parsedFilterValues.numberOfGuests);
      setNumberOfChildren(parsedFilterValues.numberOfChildren);
    }
  }, [
    location,
    checkInDate,
    checkOutDate,
    numberOfChildren,
    numberOfGuests,
    couponId,
    couponCode,
    applyButtonClicked,
    isCouponRemoved,
    isHollowCouponRemoved,
    isCustomCouponRemoved,
    isCustomDoubleDipCouponRemoved,
    isPartialPayment,
  ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    const bookingParams = searchParams.has('bookingId');
    if (
      (location.pathname.startsWith('/booking') ||
        (isDetailPage && bookingParams)) &&
      !isCheckInDatePickerOpen &&
      !isMenuOpen
    ) {
      if (
        dayjs(checkOutDate).isSame(dayjs(checkInDate)) ||
        (!checkInDate && !checkOutDate)
      ) {
        setCheckInDate(
          dayjs(fetchingBookingDetailsRes?.checkin_on).format('DD MMM, YYYY')
        );
        setCheckOutDate(
          dayjs(fetchingBookingDetailsRes?.checkout_on).format('DD MMM, YYYY')
        );
        dispatch(updateTheCalender(copyAvailabilityCalenderList));
        setCalenderModalError(false);
        return;
      } else {
        const formattedCheckInDate = dayjs(checkInDate, 'DD MMM,YYYY').format(
          'YYYY-MM-DD'
        );
        const formattedCheckOutDate = dayjs(checkOutDate, 'DD MMM,YYYY').format(
          'YYYY-MM-DD'
        );
        let data = {
          checkin_on: formattedCheckInDate,
          checkout_on: formattedCheckOutDate,
          adults: numberOfGuests,
          children: numberOfChildren,
          cashback_amount: elicashCount,
          ...(couponCode &&
            couponCode &&
            !isCustomCouponApplied &&
            !isCustomDoubleDipCouponApplied && {
              coupon_code: couponCode,

              //|| fetchingBookingDetailsRes?.Order?.Coupons[0]?.Offer?.id,
            }),
          ...(couponCode &&
            isCouponApplied && {
              is_additional_discount: true,
            }),
          ...(couponId &&
            !isCouponApplied &&
            !isCustomCouponApplied && {
              coupon_code: undefined,
              coupon_id:
                couponId ||
                fetchingBookingDetailsRes?.attached_coupon?.coupon_id,
              is_additional_discount: undefined,
            }),
          ...(isPartialPayment && {
            property_payment_term_id: pricingRefactored(
              gettingUpdatedPricing?.PaymentTerms ||
                gettingUpdatedPricing?.Property?.PaymentTerms
            )?.PropertyPaymentTerm?.id,
          }),
        };
        const searchParams = new URLSearchParams(location?.search);
        const bookingId =
          searchParams.get('id') || searchParams.get('bookingId');
        const id =
          searchParams.get('propertyId') || location.pathname.split('/').pop();
        const newBookingData = {
          bookingId: bookingId,
          guest_user_id:
            userDetails(gettingProfileDetails).guest_id ?? undefined,
          property_id: parseInt(id),
        };
        data = Object.assign(data, newBookingData);
        dispatch(updateReviewBookingDetails(data));
        const values = {
          checkInDate:
            checkInDate &&
            dayjs(checkInDate, { format: 'DD MMM, YYYY' }).format(
              'DD MMM, YYYY'
            ),
          checkOutDate:
            checkOutDate &&
            dayjs(checkOutDate, { format: 'DD MMM, YYYY' }).format(
              'DD MMM, YYYY'
            ),
          numberOfGuests: numberOfGuests || 1,
          numberOfChildren: numberOfChildren || 1,
        };
        saveFilterValues(values);
        setIsMenuOpen(false);
      }
    }
  }, [
    isCheckInDatePickerOpen,
    isMenuOpen,
    couponId,
    couponCode,
    applyButtonClicked,
    isCouponRemoved,
    isHollowCouponRemoved,
    isCustomCouponRemoved,
    isCustomDoubleDipCouponRemoved,
    isPartialPayment,
    elicashApplied,
  ]);

  useEffect(() => {
    if (location.pathname !== '/booking') {
      if (dayjs(checkOutDate).isSame(dayjs(checkInDate))) {
        dispatch(updateTheCalender(copyAvailabilityCalenderList));
        setCalenderModalError(false);
        return;
      } else if (
        checkInDate &&
        checkOutDate &&
        !isMenuOpen &&
        !isCheckInDatePickerOpen
      ) {
        const formattedCheckInDate = dayjs(checkInDate, 'DD MMM,YYYY').format(
          'YYYY-MM-DD'
        );
        const formattedCheckOutDate = dayjs(checkOutDate, 'DD MMM,YYYY').format(
          'YYYY-MM-DD'
        );
        let data = {
          property_id: id,
          checkin_on: formattedCheckInDate,
          checkout_on: formattedCheckOutDate,
          adults: numberOfGuests,
          children: numberOfChildren,
        };
        dispatch(updatingPricing(data));
      }
    }
  }, [selectDate, isCheckInDatePickerOpen, isMenuOpen]);

  const handleBookedNow = () => {
    const formattedCheckInDate = dayjs(checkInDate, 'DD MMM,YYYY').format(
      'YYYY-MM-DD'
    );
    const formattedCheckOutDate = dayjs(checkOutDate, 'DD MMM,YYYY').format(
      'YYYY-MM-DD'
    );

    let data = {
      property_id: parseInt(id),
      checkin_on: formattedCheckInDate,
      checkout_on: formattedCheckOutDate,
      adults: numberOfGuests,
      children: numberOfChildren,
      ...(isPartialPayment && {
        property_payment_term_id: pricingRefactored(
          gettingUpdatedPricing?.PaymentTerms ||
            gettingUpdatedPricing?.Property?.PaymentTerms
        )?.PropertyPaymentTerm?.id,
      }),
    };

    if (
      location.pathname !== '/booking' &&
      checkInDate &&
      checkOutDate &&
      !isMenuOpen &&
      !bookingId
    ) {
      dispatch(booking(data));
    } else {
      const newBookingData = {
        bookingId: bookingId,
      };
      data = Object.assign(data, newBookingData, {
        cashback_amount: elicashCount,
      });
      dispatch(booking(data));
    }
  };

  const handleDatePickerModal = () => {
    setIsCheckInDatePickerOpen(false);
    setIsMenuOpen(true);
    setIsModifyClicked(false);
  };
  useEffect(() => {
    if (isModifyClicked) {
      setIsCheckInDatePickerOpen(true);
    }
  }, [isModifyClicked]);

  return (
    <div className='detailCheckinCheckoutMain' id='detailCheckinCheckout'>
      <div className='detailCheckinCheckout'>
        <div
          className='detailCheckinCheckoutField'
          onClick={handleCheckInDateClick}
        >
          <TextField
            variant='filled'
            // onClick={handleCheckInDateClick}
            value={checkInDate ? checkInDate : ''}
            //----------------------
            // InputLabelProps={{
            //   startAdornment: <CalendarTodayIcon />,
            // }}
            InputProps={{
              autoComplete: 'off', // Disable autocomplete
              // startAdornment: <CalendarTodayIcon />,
              readOnly: true,
            }}
            label={
              <div className='El-calLabel'>
                <CalendarMonthOutlinedIcon color='#ccc' fontSize='small' />{' '}
                {!selectedCheckInDate ? 'Check-In' : selectedCheckInDate}
              </div>
            }
            // onFocus={handleCheckInDateClick}

            disabled={showAvailabilty}
          />
          <TextField
            variant='filled'
            value={
              checkOutDate && checkInDate !== checkOutDate ? checkOutDate : ''
            }
            //onClick={handleCheckInDateClick}
            label={
              <div className='El-calLabel'>
                <CalendarMonthOutlinedIcon color='#ccc' fontSize='small' />{' '}
                {!selectedcheckOutDate ? 'Check-Out' : selectedcheckOutDate}
              </div>
            }
            // onFocus={handleCheckInDateClick}
            // readOnly
            //disabled={isModifyClicked}
            InputProps={{ readOnly: true }}
            disabled={showAvailabilty}
          />
        </div>
        <div className='detailCheckinCheckoutButton'>
          <Button
            className='El-addGuestButton'
            fullWidth
            size='large'
            // onClick={handlePopoverOpen}
            onClick={handleMenuOpen} // Open the Menu
            variant='text'
            color='secondary'
            id='number-of-guests-button'
            endIcon={!isMobile && <KeyboardArrowDownIcon />}
          >
            <div className='El-addGuestButtonInner'>
              <PeopleAltOutlinedIcon />
              {numberOfGuests === ''
                ? 'Number of Guests'
                : `${numberOfGuests + numberOfChildren} Guest(s)`}
            </div>
          </Button>
          <GuestOpen
            isOpen={isMenuOpen}
            handleGuestClose={handleGuestClose}
            numberOfGuests={numberOfGuests}
            numberOfChildren={numberOfChildren}
            setNumberOfChildren={setNumberOfChildren}
            setNumberOfGuests={setNumberOfGuests}
            isDrawer={false}
            handleUpdatingPricing={handleUpdatingPricing}
            setBasicDetails={props.setBasicDetails}
            basicDetails={props.basicDetails}
            fetchingUpdatingPricing={fetchingUpdatingPricing}
            max_occupancy={max_occupancy}
            standard_guests={standard_guests}
            max_children={max_children}
            max_adults={max_adults}
            staahMapping={staahMapping}
          />
        </div>

        {isMobile ? (
          <DatePickerDrawer
            open={isCheckInDatePickerOpen}
            // onClose={() => setIsCheckInDatePickerOpen(false)}
            setCheckInDate={setCheckInDate}
            setCheckOutDate={setCheckOutDate}
            isDrawer={false}
            setIsClearDatesModal={setIsClearDatesModal}
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
            firstDate={firstDate}
            availabilityCalenderList={availabilityCalenderList}
            calenderModalError={calenderModalError}
            setCalenderModalError={setCalenderModalError}
            setIsCheckInDatePickerOpen={setIsCheckInDatePickerOpen}
            isCheckInDatePickerOpen={isCheckInDatePickerOpen}
            clearDatesModal={clearDatesModal}
            setGuestDrawer={setIsMenuOpen}
            isModifyClicked={isModifyClicked}
            handleDatePickerModal={handleDatePickerModal}
            setIsModifyClicked={setIsModifyClicked}
            setIsOuterClicked={setIsOuterClicked}
          />
        ) : (
          <DatePickerModal
            open={isCheckInDatePickerOpen}
            onClose={handleModalClose}
            setCheckInDate={setCheckInDate}
            setCheckOutDate={setCheckOutDate}
            isDrawer={false}
            setIsClearDatesModal={setIsClearDatesModal}
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
            firstDate={firstDate}
            availabilityCalenderList={availabilityCalenderList}
            calenderModalError={calenderModalError}
            setCalenderModalError={setCalenderModalError}
            handleDatePickerModal={handleDatePickerModal}
            isModifyClicked={isModifyClicked}
            setIsModifyClicked={setIsModifyClicked}
            clearDatesModal={clearDatesModal}
          />
        )}
      </div>

      {isbookNow && (
        <BookNow
          checkInDate={checkInDate}
          checkOutDate={checkOutDate}
          numberOfGuests={numberOfGuests}
          id={id}
          checkInQueryParam={checkInQueryParam}
          checkOutQueryParam={checkOutQueryParam}
          numberOfGuestsQueryParam={numberOfGuestsQueryParam}
          numberOfChildrenQueryParam={numberOfChildrenQueryParam}
          handleCheckInDateClick={handleCheckInDateClick}
          fetchingUpdatingPricing={fetchingUpdatingPricing}
          handleBookedNow={handleBookedNow}
          bookingId={bookingId}
          isBookingSuccessStatus={isBookingSuccessStatus}
          loader={loader}
          totalPayableAmount={totalPayableAmount}
          gettingUpdatedPricing={gettingUpdatedPricing}
        />
      )}
    </div>
  );
};

export default CheckinCheckoutDetail;
